<div class="nat-tree-container">
  <a [href]="url" target="_blank" class="org-logo-anchor">
    <img
      *ngIf="organizationLogoUrl"
      class="sidenav-img"
      [src]="organizationLogoUrl"
      alt="Portal"
      (load)="imageLoaded()"
    />
  </a>
  <div class="nav-anchor-container">
    <mat-divider></mat-divider>
    <div class="nav-anchor">
      <a *ngIf="contact" mat-flat-button [href]="contact" target="_blank">
        Contact Us
      </a>
    </div>
    <div class="nav-anchor">
      <a
        *ngIf="requestAppointment"
        mat-flat-button
        [href]="requestAppointment"
        target="_blank"
      >
        Request Appointment
      </a>
    </div>
    <mat-divider></mat-divider>
    <ng-container *ngFor="let item of navItems">
      <mat-nav-list *ngIf="item.display !== false">
        <mat-expansion-panel
          *ngIf="item.parent && roles.includes(item.role); else notParent"
          class="mat-elevation-z0"
          [expanded]="true"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="expansion-icon">{{ item.icon }}</mat-icon>
              {{ item.display }}
            </mat-panel-title>
          </mat-expansion-panel-header>
        <mat-nav-list>
          @for (sub of item.subMenu; track sub) {
          <a
            mat-list-item
            *ngIf="roles.includes(sub.role)"
            [routerLink]="[sub.link]"
            routerLinkActive="list-item-active"
            (click)="click.emit(sub)"
          >
            <span class="nav-detail">
              <mat-icon>{{ sub.icon }}</mat-icon
              >{{ sub.display }}
            </span>
          </a>
          }
        </mat-nav-list>
        </mat-expansion-panel>
        <ng-template #notParent>
          <a
            mat-list-item
            *ngIf="roles.includes(item.role)"
            [routerLink]="[item.link]"
            routerLinkActive="list-item-active"
            (click)="click.emit(item)"
          >
            <span class="nav-detail"
              ><mat-icon>{{ item.icon }}</mat-icon> {{ item.display }}</span
            >
          </a>
        </ng-template>
      </mat-nav-list>
    </ng-container>
  </div>
</div>
