<mat-sidenav-container
  fullscreen
  class="sidenav-container"
>
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport="true"
    [attr.role]="
      (layout.layout$ | async)?.handset ||
      ((layout.layout$ | async)?.tablet && (layout.layout$ | async)?.portrait)
        ? 'dialog'
        : 'navigation'
    "
    [mode]="
      (layout.layout$ | async)?.handset ||
      ((layout.layout$ | async)?.tablet && (layout.layout$ | async)?.portrait)
        ? 'over'
        : 'side'
    "
    [opened]="
      !(layout.layout$ | async)?.handset &&
      !((layout.layout$ | async)?.tablet && (layout.layout$ | async)?.portrait)
    "
  >
    <marrick-nav-tree
      [auth]="auth.auth$ | async"
      [roles]="auth.organizationRoles$ | async"
      [contact]="app.contact$ | async"
      [requestAppointment]="app.requestAppointment$ | async"
      [url]="auth.organizationUrl$ | async"
      [organizationLogoUrl]="app.organizationLogoUrl$ | async"
      [navItems]="app.navElements$ | async"
      [isLogoLoaded]="layout.logoLoaded$ | async"
      (click)="navigate($event)"
      (logoLoaded)="logoLoaded($event)"
    ></marrick-nav-tree>
  </mat-sidenav>
  <mat-sidenav-content>
    <marrick-header
      [defaultTitle]="'Portal'"
      [auth]="auth.auth$ | async"
      [roles]="auth.roles$ | async"
      [organizationId]="auth.organizationId$ | async"
      [organizations]="organizations$ | async"
      [organizationName]="auth.organizationName$ | async"
      [photoUrl]="auth.photoUrl$ | async"
      [layout]="layout.layout$ | async"
      [photoUrl]=""
      (toggleSideNav)="drawer.toggle()"
      (login)="login()"
      (logout)="logout()"
      (organization)="organization($event)"
      (switchOrganization)="switchOrganization($event)"
    ></marrick-header>
    <marrick-loading *ngIf="layout.isLoading$ | async"></marrick-loading>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <marrick-footer
      [contact]="app.contact$ | async"
      [privacyPolicy]="app.privacyPolicy$ | async"
      [termsOfService]="app.termsOfService$ | async"
      [layout]="layout.layout$ | async"
      [year]="year"
    ></marrick-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
