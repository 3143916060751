/* src/app/layout/components/nav-tree/nav-tree.component.scss */
mat-icon {
  padding-right: 10px;
  padding-left: 20px;
}
.nat-tree-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.nav-anchor-container {
  width: 100%;
}
.nav-anchor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}
.org-logo-anchor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 50px;
}
.sidenav-img {
  width: 150px;
}
a {
  text-decoration: none;
}
.list-item-active {
  background: rgba(0, 0, 0, 0.1);
}
mat-panel-title {
  font-size: 17px;
}
.expansion-icon {
  padding-right: 10px;
  padding-left: 10px;
}
.nav-detail {
  display: flex;
}
/*# sourceMappingURL=nav-tree.component.css.map */
