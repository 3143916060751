import { Injectable, inject } from '@angular/core';
import { Observable, of, combineLatest, from } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  filter,
  exhaustMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { FirestoreService, AuthService } from '../../auth/services';
import { AuthFacade } from '../../auth/+state';
import {
  ActionTypes,
  SetLoggedOutAction,
} from '../../auth/+state/auth.actions';
import { FeedbackComponent } from '../components';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './layout.actions';
import { MatDialog } from '@angular/material/dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ShowSnackbarAction } from '../../+state/app.actions';
import { SnackBarInfo } from '../../models';
import { ErrorService } from '../../core/services';
import { orderBy, where } from '@angular/fire/firestore';
import { Theme } from '../../models';
@Injectable()
export class LayoutEffects {
  constructor(
    private actions$: Actions,
    private firestoreUtils: FirestoreService,
    private auth: AuthFacade,
    private authService: AuthService,
    private dialogService: MatDialog,
    private error: ErrorService
  ) {}

  getThemes$: Observable<Action> = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(
        ofType<fromActions.LoadThemesAction>(fromActions.ActionTypes.LoadThemes)
      ),
      this.auth.organizationId$,
    ]).pipe(
      filter(([action, orgId]) => !!orgId),
      switchMap(() =>
        this.firestoreUtils
          .col$<Theme>('themes', where('isActive', '==', true))
          .pipe(
            takeUntil(
              this.authService.authState$.pipe(filter((auth) => !auth))
            ),
            map((states) => new fromActions.LoadThemesSuccessAction(states)),
            catchError((err) => of(new fromActions.LoadThemesErrorAction(err)))
          )
      )
    );
  });

  // The Effect responds to the Logout Action fired from the Header Component.
  openFeedback$: Observable<any> = createEffect(() => {
    return this.actions$.pipe(
      ofType<fromActions.OpenFeedbackAction>(
        fromActions.ActionTypes.OpenFeedback
      ),
      withLatestFrom(this.auth.uid$),
      filter(([action, uid]) => !!uid),
      exhaustMap(([action, uid]) =>
        // Open a modal dialog to confirm the user wants to logout.
        this.dialogService
          .open(FeedbackComponent)
          .afterClosed()
          .pipe(
            map((send) => {
              if (send) {
                const feedback = { feedback: send, uid };
                return new fromActions.SetFeedbackAction(feedback);
              } else {
                return new fromActions.CancelFeedbackAction();
              }
            })
          )
      )
    );
  });

  setFeedback$: Observable<Action> = createEffect(() => {
    return this.actions$
      .pipe(
        ofType<fromActions.SetFeedbackAction>(
          fromActions.ActionTypes.SetFeedback
        )
      )
      .pipe(
        switchMap((action) => {
          return from(this.firestoreUtils.add('feedback', action.payload)).pipe(
            map(() => new fromActions.SendFeedbackEmailAction(action.payload)),
            catchError((err) => of(new fromActions.SetFeedbackErrorAction(err)))
          );
        })
      );
  });

  saveFeedbackError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.SetFeedbackErrorAction>(
          fromActions.ActionTypes.SetFeedbackError
        ),
        withLatestFrom(this.auth.uid$),
        map(([action, uid]) => {
          const message =
            'An error occurred saving feedback. Feedback not sent.';
          this.error.showErrorMessage(uid, message, action.payload);
        })
      );
    },
    { dispatch: false }
  );

  sendFeedbackEmail$: Observable<Action> = createEffect(() => {
    return this.actions$
      .pipe(
        ofType<fromActions.SendFeedbackEmailAction>(
          fromActions.ActionTypes.SendFeedbackEmail
        )
      )
      .pipe(
        switchMap((action) =>
          this.authService
            .sendFeedback(action.payload.uid, action.payload.feedback)
            .pipe(
              map(() => new fromActions.SendFeedbackEmailSuccessAction()),
              catchError((err) =>
                of(new fromActions.SendFeedbackEmailErrorAction(err))
              )
            )
        )
      );
  });

  sendFeedbackEmailError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.SendFeedbackEmailErrorAction>(
          fromActions.ActionTypes.SendFeedbackEmailError
        ),
        withLatestFrom(this.auth.uid$),
        map(([action, uid]) => {
          const message =
            'An error occurred sending feedback. Feedback not sent.';
          this.error.showErrorMessage(uid, message, action.payload);
        })
      );
    },
    { dispatch: false }
  );

  setFeedbackEmailSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$
      .pipe(
        ofType<fromActions.SendFeedbackEmailSuccessAction>(
          fromActions.ActionTypes.SendFeedbackEmailSuccess
        )
      )
      .pipe(
        map(() => {
          const snackBarInfo: SnackBarInfo = {
            message: `Thank you for providing feedback.`,
            duration: 5000,
            action: 'OK',
          };
          return new ShowSnackbarAction(snackBarInfo);
        })
      );
  });

  logout$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<SetLoggedOutAction>(ActionTypes.SetLoggedOut),
      map((action) => new fromActions.ClearStateAction())
    );
  });
}
