import {
  HttpInterceptorFn,
  HttpParameterCodec,
  HttpParams,
} from '@angular/common/http';

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export const paramsEncoderInterceptor: HttpInterceptorFn = (req, next) => {
  const params = new HttpParams({
    encoder: new CustomEncoder(),
    fromString: req.params.toString(),
  });
  return next(req.clone({ params }));
};
