import { inject } from '@angular/core';

import { HttpInterceptorFn } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { LoggerService } from '../../services';
import { AuthFacade } from 'src/app/auth/+state';
import { AppFacade } from 'src/app/+state';
import { SnackBarInfo } from 'src/app/models';

export const httpAuthCheckInterceptor: HttpInterceptorFn = (req, next) => {
  const appFacade: AppFacade = inject(AppFacade);
  const authFacade: AuthFacade = inject(AuthFacade);
  const loggerService: LoggerService = inject(LoggerService);

  const authHeader = req.headers.get('Authorization');
  if (authHeader) {
    const decodedToken = decodeToken(authHeader);
    if (!tokenVersionMatch(decodedToken, loggerService)) {
      //   force the user to logout and cancel the http request
      const snackBarInfo: SnackBarInfo = {
        message: 'New authentication version required. Please login again.',
        action: 'OK',
        duration: 10000,
        style: ['error-text'],
      };
      appFacade.showSnackbar(snackBarInfo);
      loggerService.log(
        `UID: ${decodedToken.email} Forcing user to logout due to token version match check failing.`
      );
      authFacade.logoutConfirmed();
      return EMPTY;
    }
  }
  return next(req);
};

function tokenVersionMatch(
  decodedToken,
  loggerService: LoggerService
): boolean {
  //   console.log('decodedToken', decodedToken);
  if (!decodedToken || !decodedToken.version) {
    loggerService.log(`UID: ${decodedToken.email} Token version missing.`);
    return false;
  }
  const expectedVersion = 2;
  const passing = decodedToken.version === expectedVersion;
  if (!passing) {
    console.log(
      'Token version mismatch',
      decodedToken.version,
      expectedVersion
    );
    loggerService.log(
      `UID: ${decodedToken.email} Token version mismatch: ${decodedToken.version} !== ${expectedVersion}`
    );
  }
  return passing;
}

function decodeToken(token: string): any {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64UrlDecode(base64Url);
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
}

function base64UrlDecode(base64Url: string): string {
  // Replace URL-safe characters
  base64Url = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  // Pad with '=' characters to make length a multiple of 4
  while (base64Url.length % 4) {
    base64Url += '=';
  }
  return base64Url;
}
