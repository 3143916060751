/* src/app/layout/components/loading/loading.component.scss */
.spinner {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  position: fixed;
  z-index: 1500;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.spinner-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 1005;
  z-index: 1000;
}
.spinner > div {
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect1 {
  margin-right: 5px;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
  margin-right: 5px;
}
.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  margin-right: 5px;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
  margin-right: 5px;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  margin-right: 5px;
}
@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
/*# sourceMappingURL=loading.component.css.map */
