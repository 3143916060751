import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Logging Module
import { LoggerModule } from 'ngx-logger';

// Eagerly loaded modules
import { LayoutModule } from './layout/layout.module';
import { AuthModule } from './auth/auth.module';
import { PreferencesModule } from './preferences/preferences.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './+state/app.effects';
import { reducers, metaReducers } from './+state';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { provideFirestore, initializeFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { providePerformance, getPerformance } from '@angular/fire/performance';

import {
  paramsEncoderInterceptor,
  httpAuthCheckInterceptor,
} from './core/interceptors';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    PreferencesModule,
    AuthModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([AppEffects]),
    AppRoutingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production || environment.environment === 'DEMO',
      name: environment.production
        ? 'Portal'
        : `${environment.environment} Portal`,
      connectInZone: true,
    }),
    LoggerModule.forRoot(environment.logging),
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    provideFirestore(() => {
      const app = getApp();
      const firestore = initializeFirestore(
        app,
        {},
        environment.firebaseConfig.databaseId
      );
      return firestore;
    }),
    provideStorage(() => getStorage()),
    providePerformance(() => getPerformance()),
    provideHttpClient(
      withInterceptors([paramsEncoderInterceptor, httpAuthCheckInterceptor])
    ),
  ],
})
export class AppModule {}
