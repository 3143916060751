/* src/app/layout/components/header/presentation/header.component.scss */
.fill-remaining-space {
  flex: 1 1 auto;
}
a {
  cursor: pointer;
}
.heading {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
mat-icon {
  cursor: pointer;
}
.heading {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.margin-top-right {
  margin-top: 10px;
  margin-right: 15px;
}
.margin-top {
  margin-top: 50px;
}
img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}
.color-swatch {
  position: relative;
  height: 42px;
  width: 21px;
  margin: 2px;
  border-radius: 100%;
  overflow: hidden;
}
.color-swatch-primary {
  background-color: blue;
}
.color-swatch-green {
  background-color: green;
}
.color-swatch-purple {
  background-color: purple;
}
/*# sourceMappingURL=header.component.css.map */
