/* src/app/layout/components/layout/layout.component.scss */
mat-sidenav-container {
  position: fixed;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  min-width: 100%;
}
.sidenav-container {
  height: 100%;
}
.sidenav {
  width: 250px;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.24);
}
.content {
  padding: 20px;
}
/*# sourceMappingURL=layout.component.css.map */
