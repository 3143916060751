import { Injectable, inject } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService, FirestoreService } from '../auth/services';
import { SnackbarService } from '../core/services';
import { AuthFacade } from '../auth/+state/';
import { Observable, of, combineLatest } from 'rxjs';
import {
  switchMap,
  map,
  filter,
  catchError,
  takeUntil,
  withLatestFrom,
  tap,
} from 'rxjs/operators';
import * as fromActions from './app.actions';
import { ActionTypes, SetLoggedOutAction } from '../auth/+state/auth.actions';
import { ErrorService } from '../core/services';
import { LoadDocTypesSuccessAction } from '../+state/app.actions';
import { PortalConfiguration } from '../models';
import { OverlayContainer } from '@angular/cdk/overlay';
import { LayoutFacade } from '../layout/+state';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private auth: AuthFacade,
    private error: ErrorService,
    private firestoreUtils: FirestoreService,
    private snackbar: SnackbarService,
    private authService: AuthService,
    private overlayContainer: OverlayContainer,
    private layout: LayoutFacade
  ) {}

  loadStates$: Observable<Action> = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(
        ofType<fromActions.LoadStatesAction>(fromActions.ActionTypes.LoadStates)
      ),
      this.auth.authenticated$,
    ]).pipe(
      filter(([_, auth]) => !!auth),
      switchMap(() =>
        this.firestoreUtils.doc$<{ data: [] }>(`codes/states`).pipe(
          takeUntil(this.authService.authState$.pipe(filter((auth) => !auth))),
          map((states) => new fromActions.LoadStatesSuccessAction(states.data)),
          catchError((err) => of(new fromActions.LoadStatesErrorAction(err)))
        )
      )
    );
  });

  loadStatesError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.LoadStatesErrorAction>(
          fromActions.ActionTypes.LoadStatesError
        ),
        withLatestFrom(this.auth.uid$),
        map(([action, uid]) => {
          const message = 'An error occurred retrieving States.';
          this.error.showErrorMessage(uid, message, action.payload);
        })
      );
    },
    { dispatch: false }
  );

  loadPrefixes$: Observable<Action> = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(
        ofType<fromActions.LoadPrefixesAction>(
          fromActions.ActionTypes.LoadPrefixes
        )
      ),
      this.auth.authenticated$,
    ]).pipe(
      filter(([action, auth]) => !!auth),
      switchMap(() =>
        this.firestoreUtils.doc$<{ data: [] }>(`codes/prefixes`).pipe(
          takeUntil(this.authService.authState$.pipe(filter((auth) => !auth))),
          map(
            (prefixes) =>
              new fromActions.LoadPrefixesSuccessAction(prefixes.data)
          ),
          catchError((err) => of(new fromActions.LoadPrefixesErrorAction(err)))
        )
      )
    );
  });

  loadPrefixesError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.LoadPrefixesErrorAction>(
          fromActions.ActionTypes.LoadPrefixesError
        ),
        withLatestFrom(this.auth.uid$),
        map(([action, uid]) => {
          const message = 'An error occurred retrieving Prefixes.';
          this.error.showErrorMessage(uid, message, action.payload);
        })
      );
    },
    { dispatch: false }
  );

  loadPhoneTypes$: Observable<Action> = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(
        ofType<fromActions.LoadPhoneTypesAction>(
          fromActions.ActionTypes.LoadPhoneTypes
        )
      ),
      this.auth.authenticated$,
    ]).pipe(
      filter(([_, auth]) => !!auth),
      switchMap(() =>
        this.firestoreUtils.doc$<{ data: [] }>(`codes/phone-types`).pipe(
          takeUntil(this.authService.authState$.pipe(filter((auth) => !auth))),
          map((doc) => new fromActions.LoadPhoneTypesSuccessAction(doc.data)),
          catchError((err) =>
            of(new fromActions.LoadPhoneTypesErrorAction(err))
          )
        )
      )
    );
  });

  loadPhoneTypesError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.LoadPhoneTypesErrorAction>(
          fromActions.ActionTypes.LoadPhoneTypesError
        ),
        withLatestFrom(this.auth.uid$),
        map(([action, uid]) => {
          const message = 'An error occurred retrieving Phone Types.';
          this.error.showErrorMessage(uid, message, action.payload);
        })
      );
    },
    { dispatch: false }
  );

  setTheme$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.SetThemeNameAction>(
          fromActions.ActionTypes.SetThemeName
        ),
        map((action) => {
          const theme = action.payload;
          const overlayContainerClasses =
            this.overlayContainer.getContainerElement().classList;
          const themeClassesToRemove = Array.from(
            overlayContainerClasses
          ).filter((item: string) => item.includes('_theme'));
          if (themeClassesToRemove.length) {
            overlayContainerClasses.remove(...themeClassesToRemove);
          }
          overlayContainerClasses.add(theme);
        })
      );
    },
    { dispatch: false }
  );

  loadPortalConfig$: Observable<Action> = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(
        ofType<fromActions.GetPortalConfigAction>(
          fromActions.ActionTypes.GetPortalConfig
        )
      ),
      this.auth.authenticated$,
      this.auth.organization$,
    ]).pipe(
      filter(([action, authenticated, org]) => !!(authenticated && org)),
      tap(() =>
        this.layout.setLoadingOn(fromActions.ActionTypes.GetPortalConfig)
      ),
      switchMap(([action, authenticated, organization]) => {
        // If the user is readonly get the config from it's Organization Parent.
        let orgId = organization.id;
        if (
          organization.parents &&
          organization.parents.length > 0 &&
          organization.parents[0].length > 0
        ) {
          // Hard code the first Parent until portal configuration can be
          // properly implemented.
          orgId = organization.parents[0];
        }
        return this.firestoreUtils
          .doc$<PortalConfiguration>(`configuration/${orgId}`)
          .pipe(
            takeUntil(
              this.authService.authState$.pipe(filter((auth) => !auth))
            ),
            map(
              (config) => new fromActions.GetPortalConfigSuccessAction(config)
            ),
            tap(() =>
              this.layout.setLoadingOff(fromActions.ActionTypes.GetPortalConfig)
            ),
            catchError((err) => {
              this.layout.setLoadingOff(
                fromActions.ActionTypes.GetPortalConfig
              );
              return of(new fromActions.GetPortalConfigErrorAction(err));
            })
          );
      })
    );
  });

  loadPortalConfigSuccess$: Observable<Action> = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(
        ofType<fromActions.GetPortalConfigSuccessAction>(
          fromActions.ActionTypes.GetPortalConfigSuccess
        )
      ),
      this.auth.authenticated$,
    ]).pipe(
      filter(([action, authenticated]) => !!authenticated),
      switchMap(([action, authenticated]) => [
        new LoadDocTypesSuccessAction(action.payload.docTypes),
        new fromActions.LoadDocumentGenerationSuccessAction(
          action.payload.documentGeneration
        ),
        new fromActions.LoadTransactionTypesSuccessAction(
          action.payload.transactionTypes
        ),
      ]),
      catchError((err) => of(new fromActions.GetPortalConfigErrorAction(err)))
    );
  });

  loadPortalConfigError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.GetPortalConfigErrorAction>(
          fromActions.ActionTypes.GetPortalConfigError
        ),
        withLatestFrom(this.auth.uid$),
        map(([action, uid]) => {
          const message = 'An error occurred retrieving Portal configuration.';
          this.error.showErrorMessage(uid, message, action.payload);
        })
      );
    },
    { dispatch: false }
  );

  showSnackBar$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.ShowSnackbarAction>(
          fromActions.ActionTypes.ShowSnackBar
        ),
        map((action) => this.snackbar.showSnackBar(action.payload))
      );
    },
    { dispatch: false }
  );

  showSnackBarError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.ShowSnackbarErrorAction>(
          fromActions.ActionTypes.ShowSnackBarError
        ),
        map((action) => this.snackbar.showSnackBarError(action.payload))
      );
    },
    { dispatch: false }
  );

  logout$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<SetLoggedOutAction>(ActionTypes.SetLoggedOut),
      map((action) => new fromActions.ClearStateAction())
    );
  });
}
