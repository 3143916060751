import { Injectable, inject } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  filter,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthFacade } from '../../auth/+state';
import { PreferencesService } from '../services/preferences.service';
import { ErrorService } from '../../core/services';
import { PreferencesFacade } from './preferences.facade';
import {
  ActionTypes,
  SetLoggedOutAction,
} from '../../auth/+state/auth.actions';
import * as fromActions from './preferences.actions';
import { AuthService } from 'src/app/auth/services';

@Injectable()
export class PreferencesEffects {
  constructor(
    private actions$: Actions,
    private auth: AuthFacade,
    private preferencesService: PreferencesService,
    private preferences: PreferencesFacade,
    private error: ErrorService,
    private authService: AuthService
  ) {}

  loadPreferences$: Observable<Action> = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(
        ofType<fromActions.LoadPreferencesAction>(
          fromActions.ActionTypes.LoadPreferences
        )
      ),
      this.auth.authenticated$,
      this.auth.uid$,
    ]).pipe(
      filter(([action, auth, uid]) => !!auth),
      switchMap(([action, authenticated, uid]) => {
        return this.preferencesService.retrievePreferences(uid).pipe(
          takeUntil(this.authService.authState$.pipe(filter((auth) => !auth))),
          map((doc) => {
            if (doc && doc.themeName) {
              return new fromActions.LoadPreferencesSuccessAction(doc);
            } else {
              return new fromActions.NoopAction();
            }
          }),
          catchError((err) =>
            of(new fromActions.LoadPreferencesErrorAction(err))
          )
        );
      })
    );
  });

  loadPreferencesError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.LoadPreferencesErrorAction>(
          fromActions.ActionTypes.LoadPreferencesError
        ),
        withLatestFrom(this.auth.uid$),
        map(([action, uid]) => {
          const message = 'An error occurred loading Preferences.';
          this.error.showErrorMessage(uid, message, action.payload);
        })
      );
    },
    { dispatch: false }
  );

  saveItemsPerPage$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<fromActions.SaveItemsPerPageAction>(
        fromActions.ActionTypes.SaveItemsPerPage
      ),
      withLatestFrom(this.auth.uid$),
      withLatestFrom(this.preferences.preferences$),
      switchMap(([[action, uid], preferences]) => {
        const newItemPerPage = {
          ...preferences.itemsPerPage,
          ...action.payload,
        };
        return this.preferencesService
          .persistPreferences(uid, {
            ...preferences,
            itemsPerPage: { ...newItemPerPage },
          })
          .pipe(
            map((res) => new fromActions.SaveItemsPerPageSuccessAction()),
            catchError((err) =>
              of(new fromActions.SaveItemsPerPageErrorAction(err))
            )
          );
      })
    );
  });

  saveItemsPerPageError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.SaveItemsPerPageErrorAction>(
          fromActions.ActionTypes.SaveItemsPerPageError
        ),
        withLatestFrom(this.auth.uid$),
        map(([action, uid]) => {
          const message =
            'An error occurred saving Items Per Page Preferences.';
          this.error.showErrorMessage(uid, message, action.payload);
        })
      );
    },
    { dispatch: false }
  );

  saveThemeName$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<fromActions.SaveThemeNameAction>(
        fromActions.ActionTypes.SaveThemeName
      ),
      withLatestFrom(this.auth.uid$),
      withLatestFrom(this.preferences.preferences$),
      switchMap(([[action, uid], preferences]) => {
        return this.preferencesService
          .persistPreferences(uid, {
            ...preferences,
            themeName: action.payload,
          })
          .pipe(
            map((res) => new fromActions.SaveThemeNameSuccessAction()),
            catchError((err) =>
              of(new fromActions.SaveThemeNameErrorAction(err))
            )
          );
      })
    );
  });

  saveThemeNameError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromActions.SaveThemeNameErrorAction>(
          fromActions.ActionTypes.SaveThemeNameError
        ),
        withLatestFrom(this.auth.uid$),
        map(([action, uid]) => {
          const message = 'An error occurred saving Theme Preference.';
          this.error.showErrorMessage(uid, message, action.payload);
        })
      );
    },
    { dispatch: false }
  );

  logout$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<SetLoggedOutAction>(ActionTypes.SetLoggedOut),
      map((action) => new fromActions.ClearStateAction())
    );
  });
}
