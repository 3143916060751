import { NgModule, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreModule } from '../core/core.module';
import { StoreModule, Store } from '@ngrx/store';
import { LayoutFacade } from './+state';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './+state/layout.reducer';
import { LayoutEffects } from './+state/layout.effects';

import {
  LayoutComponent,
  LoadingComponent,
  NavTreeComponent,
  HeaderComponent,
  FooterComponent,
  NotFoundComponent,
  FeedbackComponent,
} from './components';
import { LocationNamePipe } from '../patients/pipes/location-name.pipe';
import { ConfigurationsModule } from '../configurations/configurations.module';

@NgModule({
  declarations: [
    LayoutComponent,
    LoadingComponent,
    NavTreeComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    FeedbackComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    ReactiveFormsModule,
    ConfigurationsModule,
    StoreModule.forFeature('layout', reducer),
    EffectsModule.forFeature([LayoutEffects]),
    LocationNamePipe,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {
  constructor() {}
}
