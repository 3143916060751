import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { AppFacade } from './+state';
import { LayoutFacade } from './layout/+state';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'marrick-root',
  template: `
    <div *ngIf="app.themeName$ | async as theme">
      <div [class]="theme">
        <div class="mat-app-background">
          <marrick-layout></marrick-layout>
        </div>
      </div>
    </div>
  `,
})
export class AppComponent implements OnDestroy {
  // Create an Observable to help manage long lived subscriptions
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public app: AppFacade,
    public layout: LayoutFacade,
    private router: Router,
    private titleService: Title
  ) {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.layout.setLoadingOn('navigation');
            break;
          }
          case event instanceof NavigationEnd: {
            this.layout.setLoadingOff('navigation');
            break;
          }
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.layout.setLoadingOff('navigation');
            break;
          }
          default: {
            break;
          }
        }
      });

    this.titleService.setTitle(environment.title);
    this.app.loadThemeName(environment.themeName);
    this.app.loadApiRoot(environment.apiRoot);
    this.app.loadOrganizationLogoUrl(environment.organizationLogoUrl);
    this.app.loadEnvironment(environment.environment);
    this.app.loadEntityType(environment.entityType);
    this.app.loadResetPasswordHTML(environment.resetPasswordHTML);
    this.app.loadCompiledPDFsRoot(environment.compiledPDFsRoot);
    this.app.loadStates();
    this.app.loadPrefixes();
    this.app.loadPhoneTypes();
    this.app.loadPortalConfiguration();
    this.layout.loadThemes();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
