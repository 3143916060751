/* angular:styles/component:css;d7a3591dbe87e73a29f637aaf5726599ead8a3198f173a05a0d117e2f9cb805e;/home/runner/work/tao/tao/ui/src/app/layout/components/footer/footer.component.ts */
.footer {
  height: 40px;
  font-size: 12px;
}
.fill-remaining-space {
  flex: 1 1 auto;
}
mat-icon {
  font-size: 12px;
  height: 12px;
  width: 12px;
  margin-left: 10px;
}
.mat-mdc-unelevated-button {
  font-size: 12px;
  background-color: transparent;
}
.hide-column {
  display: none;
}
/*# sourceMappingURL=footer.component.css.map */
