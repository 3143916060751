/* angular:styles/component:css;fcc970b80c3b5aa5286c9b0f1b53006724261e707a06e2d2c71b5451ec8f2f95;/home/runner/work/tao/tao/ui/src/app/layout/components/feedback/feedback.component.ts */
mat-dialog-content {
  width: 300px;
  height: 200px;
}
mat-dialog-actions {
  flex-direction: row-reverse;
}
button {
  margin-left: 10px;
}
.description {
  border: solid;
  border-width: 1px;
  border-color: black;
  box-sizing: border-box;
  box-shadow: none;
  color: rgb(33, 33, 33);
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 16px;
  line-height: normal;
  font-family:
    Roboto,
    RobotoDraft,
    Helvetica,
    Arial,
    sans-serif;
  padding: 18px 16px 0px;
  resize: none;
  width: 96%;
  height: 96%;
  margin: 2px;
}
/*# sourceMappingURL=feedback.component.css.map */
