<div *ngIf="auth; else notLoggedIn">
  <!-- User is logged in -->
  <mat-toolbar color="primary" class="header">
    <a (click)="toggleSideNav.emit()" class="margin-top-right">
      <mat-icon>menu</mat-icon>
    </a>
    <span class="heading">{{ displayHeading() }}</span>
    <span class="fill-remaining-space"></span>
    <!-- <mat-icon [matMenuTriggerFor]="theme">palette</mat-icon> -->
    <a mat-icon-button [matMenuTriggerFor]="menuMore">
      <mat-icon>account_box</mat-icon>
    </a>
    <!-- There remains a defect in @angular/material for [matMenuTriggerFor]
    When more than one [matMenuTriggerFor] call the same menu item, an 
    error is logged. -->
  
    <mat-menu #menuMore="matMenu">
      <button mat-menu-item routerLink="/profile">
        <mat-icon>dialpad</mat-icon>
        <span>My Profile</span>
      </button>
      <button mat-menu-item (click)="logout.emit()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
      <mat-divider *ngIf="organizations.length > 0"></mat-divider>
      <button
        *ngIf="organizations.length > 0"
        mat-menu-item
        [matMenuTriggerFor]="orgs"
      >
        <mat-icon>business</mat-icon>
        <span>Switch Organization</span>
      </button>
    </mat-menu>
    <mat-menu #orgs="matMenu">
      <button
        mat-menu-item
        *ngFor="let org of organizations"
        (click)="switchOrganization.emit(org.id)"
      >
        {{ org.name | locationName }}
      </button>
    </mat-menu>
  </mat-toolbar>
</div>
<ng-template #notLoggedIn>
  <!-- User is not logged in -->
  <mat-toolbar color="primary" class="header">
    <a (click)="toggleSideNav.emit()" class="margin-top-right">
      <mat-icon>menu</mat-icon>
    </a>
    <span *ngIf="organizationName">{{ organizationName }}</span
    >&nbsp;Portal <span class="fill-remaining-space"></span>
    <mat-icon [matMenuTriggerFor]="menuLogin">account_circle</mat-icon>
    <mat-menu #menuLogin="matMenu">
      <a mat-menu-item (click)="login.emit()">Login</a>
    </mat-menu>
  </mat-toolbar>
</ng-template>
