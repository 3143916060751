import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CustomSerializer } from './router/+state/router.reducer';
import { RouterEffects } from './router/+state/router.effects';

import {
  routerReducer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { RouterModule } from '@angular/router';

import {
  AccountErrorComponent,
  LoginComponent,
  PasswordResetComponent,
  RequestPasswordResetComponent,
} from './auth/components';
import {
  AuthGuardAdminService,
  AuthGuardMarrickService,
  AuthGuardQaAdminService,
  AuthGuardUserService,
} from './auth/services';
import { NotFoundComponent } from './layout/components';
import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/patients',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'password-reset-request',
    component: RequestPasswordResetComponent,
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
  },
  {
    path: 'dashboard',
    canActivateChild: [AuthGuardQaAdminService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'reports',
    canActivateChild: [AuthGuardQaAdminService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'organizations',
    canActivateChild: [AuthGuardAdminService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./organizations/organizations.module').then(
        (m) => m.OrganizationsModule
      ),
  },
  {
    path: 'patients',
    canActivateChild: [AuthGuardUserService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./patients/patients.module').then((m) => m.PatientsModule),
  },
  {
    path: 'documents',
    canActivateChild: [AuthGuardUserService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./documents/documents.module').then((m) => m.DocumentsModule),
  },
  {
    path: 'credentials',
    canActivateChild: [AuthGuardAdminService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./credentials/credentials.module').then(
        (m) => m.CredentialsModule
      ),
  },
  {
    path: 'transactions',
    canActivateChild: [AuthGuardUserService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./transactions/transactions.module').then(
        (m) => m.TransactionsModule
      ),
  },
  {
    path: 'profile',
    canActivateChild: [AuthGuardUserService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'admin',
    canActivateChild: [AuthGuardAdminService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'internal',
    canActivateChild: [AuthGuardMarrickService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./internal/internal.module').then((m) => m.InternalModule),
  },
  {
    path: 'account-error',
    component: AccountErrorComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {}),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forFeature('router', routerReducer),
    EffectsModule.forFeature([RouterEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
  ],
})
export class AppRoutingModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: AppRoutingModule
  ) {
    if (parentModule) {
      throw new Error(
        'StateModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(): ModuleWithProviders<AppRoutingModule> {
    return {
      ngModule: AppRoutingModule,
      providers: [
        {
          provide: RouterStateSerializer,
          useClass: CustomSerializer,
        },
      ],
    };
  }
}
