import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';

@Component({
  selector: 'marrick-feedback',
  template: `
    <form [formGroup]="form" (submit)="send()">
      <h2 mat-dialog-title>Send Feedback</h2>
      <mat-dialog-content>
        <textarea
          autofocus
          class="description"
          formControlName="feedback"
        ></textarea>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="send()"
          [disabled]="form.invalid"
        >
          SEND
        </button>
        <button
          mat-raised-button
          type="button"
          color="accent"
          (click)="cancel()"
        >
          CANCEL
        </button>
      </mat-dialog-actions>
    </form>
  `,
  styles: [
    `
      mat-dialog-content {
        width: 300px;
        height: 200px;
      }

      mat-dialog-actions {
        flex-direction: row-reverse;
      }

      button {
        margin-left: 10px;
      }

      .description {
        border: solid;
        border-width: 1px;
        border-color: black;
        box-sizing: border-box;
        box-shadow: none;
        color: rgb(33, 33, 33);
        font-style: normal;
        font-variant: normal;
        font-weight: 400;
        font-stretch: normal;
        font-size: 16px;
        line-height: normal;
        font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
        padding: 18px 16px 0px;
        resize: none;
        width: 96%;
        height: 96%;
        margin: 2px;
      }
    `,
  ],
})
export class FeedbackComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<FeedbackComponent>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.form = this.fb.group({ feedback: ['', [Validators.required]] });
  }

  get feedback() {
    return this.form.get('feedback').value;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  send() {
    this.dialogRef.close(this.feedback);
  }
}
