import { Layout, Nav, Theme } from '../../models';
import * as actions from './layout.actions';

export interface State {
  layout: Layout;
  isLoading: boolean;
  selectedTheme: Theme;
  themes: Array<Theme>;
  error: any;
  logoLoaded: boolean;
  feedback: any;
}

export const initialState: State = {
  layout: {
    handset: false,
    tablet: false,
    web: true,
    portrait: true,
    landscape: false,
  },
  isLoading: false,
  selectedTheme: null,
  themes: [],
  error: null,
  logoLoaded: false,
  feedback: null,
};

export function reducer(
  state: State = initialState,
  action: actions.LayoutActionsUnion
): State {
  switch (action.type) {
    case actions.ActionTypes.SetLayout: {
      return { ...state, layout: action.payload };
    }
    case actions.ActionTypes.SetLoadingOn: {
      return { ...state, isLoading: true };
    }
    case actions.ActionTypes.SetLoadingOff: {
      return { ...state, isLoading: false };
    }

    case actions.ActionTypes.SetTheme: {
      return { ...state, selectedTheme: action.payload };
    }
    case actions.ActionTypes.LoadThemesSuccess: {
      return { ...state, themes: action.payload };
    }
    case actions.ActionTypes.LoadThemesError: {
      return { ...state, error: action.payload };
    }
    case actions.ActionTypes.SetLogoLoaded: {
      return { ...state, logoLoaded: action.payload };
    }
    case actions.ActionTypes.ClearState: {
      return {
        ...state,
        themes: [],
        isLoading: true,
        logoLoaded: false,
        feedback: null,
      };
    }
    default: {
      return state;
    }
  }
}
